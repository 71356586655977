<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgMsgClose"></DlgMessage>
        <DlgPtsCampaignAdd ref="dlgCommon" :visible="visible" @close="dlgClose"/>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Campaign</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon dark class="primary mr-2" @click="refreshDataTable"><v-icon>mdi-refresh</v-icon></v-btn>         
        <v-btn icon dark class="mb-2 success" @click="addRecord">
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        </v-card-title>
        <v-card-text>

        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-text>

        <v-data-table
        v-model="selected"  
        ref="vuetable"
        item-key="created_date"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"    
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>

</template>
<script>
import ApEarnedService from '@/services/ApEarnedService'
import DlgPtsCampaignAdd from "@/components/DlgPtsCampaignAdd";
import DlgMessage from "@/components/DlgMessage";
export default {
    components:{DlgPtsCampaignAdd,DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Point Settings',disabled: false,href:'#',},
              {text:'Campaign',disabled: false,href:'#',},
              {text:'List',disabled: false,href:'#',},
              ],
            selected: [],
            tblHeaders:[
                { text:"Code",value: "ec_code"  },
                { text:"Name", value:"ec_name" },
                { text:"Start Date", value:"start_date" },
                { text:"End Date", value:"end_date" },
                { text:"Status", value:"status" },

            ],
            tblBody:[],
            tblSearch:'',
            loadingFlag : false,
            visible:false,
            form:{login_id:""},

        }
    },
    async created() {
      this.refreshDataTable();
    },
    methods:{
            async refreshDataTable(){

                this.loadingFlag = true
                let title = 'System';
                try{
                    ApEarnedService.list_earned_campaign(this.form).then((res) => {
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                    }).catch((e)=>{
                            //console.log(e.response);
                        if(e.response.data.error_message)
                            this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                        this.loadingFlag = false;
                    })
                        ;
                } catch(e) {
                    if(e)
                        //this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                        alert(e)
                    this.loadingFlag = false;
                } 
            },
            openDetail(value){
                console.log(value)
                this.$router.push({ name: 'pts_campaign_details', params: { earned_campaign_id: value.earned_campaign_id } })
                //this.$router.push("/merchants/detail/" + value.merchant_id);
            },

            addRecord(){
                this.visible = true;
            },
            dlgClose(){
                this.visible = false;
                this.refreshDataTable();
            },
            dlgMsgClose(){
            },

    }

}
</script>